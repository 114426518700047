import { modulesRoutes } from "./modulesRoutes";

export const serviceRoutes = {
  path: "/service/list",
  meta: {
    breadcrumbs: {
      title: "Serviços",
      label: "Serviços",
      icon: "list",
      destination: `/service/list`,
    },
  },
  component: () => import("@/pages/Homologation/Services/"),
  children: [
    {
      path: "/homologation/:homologation_id/service/list",
      meta: {
        breadcrumbs: {
          title: "Listagem de Serviços",
          label: "Listagem de Serviços",
          icon: "list",
          destination: `/service/list`,
        },
      },
      component: () => import("@/pages/Homologation/Services/List"),
    },
    {
      path: "/service/list",
      meta: {
        breadcrumbs: {
          title: "Listagem de Serviços",
          label: "Listagem de Serviços",
          icon: "list",
          destination: `/service/list`,
        },
      },
      component: () => import("@/pages/Homologation/Services/List"),
    },
    {
      path: "/homologation/:homologation_id/service/add",
      meta: {
        breadcrumbs: {
          title: "Adicionar serviço a homologação",
          label: "Adicionar Serviço",
          icon: "ui-checks",
          destination: "#",
        },
        transition: "fade",
      },
      component: () => import("@/pages/Homologation/Services/Edit/"),
      children: [
        {
          path: "/homologation/:homologation_id/service/add/details",
          meta: {
            breadcrumbs: {
              title: "Detalhes do serviço",
              label: "Detalhes",
              icon: "ui-checks",
              destination: "#",
            },
            transition: "fade",
          },
          component: () => import("@/pages/Homologation/Services/Edit/Details"),
        },
      ],
    },
    {
      path: "/homologation/:homologation_id/service/:service_id/edit",
      meta: {
        breadcrumbs: {
          title: "Editar serviço",
          label: "Editar serviço",
          icon: "pen",
          destination: "#",
        },
        transition: "fade",
      },
      component: () => import("@/pages/Homologation/Services/Edit/"),
      children: [
        {
          path:
            "/homologation/:homologation_id/service/:service_id/edit/details",
          meta: {
            breadcrumbs: {
              title: "Detalhes do serviço",
              label: "Detalhes",
              icon: "ui-checks",
              destination: "#",
            },
            transition: "fade",
          },
          component: () => import("@/pages/Homologation/Services/Edit/Details"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/edit/premises",
          meta: {
            breadcrumbs: {
              title: "Premissas do serviço",
              label: "Premissas",
              icon: "ui-checks",
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Edit/RequestPremises/"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/edit/premises-analysis",
          meta: {
            breadcrumbs: {
              title: "Análise das premissas",
              label: "análise",
              icon: "ui-checks",
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Edit/TemplateIndicatorConfig/"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/edit/questionaire-service",
          meta: {
            breadcrumbs: {
              title: "Configurar questionário",
              label: "Questionários",
              icon: "ui-checks",
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Edit/QuestionaireService/"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/edit/document-service",
          meta: {
            breadcrumbs: {
              title: "Configurar documentos",
              label: "Documentos",
              icon: "file-copy-alt",
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Edit/Documents"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/edit/invites",
          meta: {
            breadcrumbs: {
              title: "Convites para este serviço",
              label: "Convites",
              icon: "envelope",
              destination: "#",
            },
            transition: "fade",
          },
          component: () => import("@/pages/Homologation/Services/Edit/Invites"),
        },
      ],
    },
    /**
     * @type VALIDATION
     */
    // HOMOLOGATION VALIDATION
    {
      path: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,

      meta: {
        breadcrumbs: {
          title: "Validação do serviço",
          label: "Validação",
          icon: "card-checklist",
          destination: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
        },
        transition: "fade",
      },

      component: () => import("@/pages/Homologation/Services/Validation"),
      children: [
        {
          path:
            "/homologation/:homologation_id/service/:service_id/borrower/validation",
          meta: {
            breadcrumbs: {
              title: "Validação do serviço",
              label: "Validação",
              icon: "card-checklist",
              destination: `/homologation/:homologation_id/service/:service_id/borrower/validation`,
            },
            transition: "fade",
          },
          component: () => import("@/pages/Homologation/Services/Validation/Borrower"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/provider/validation",
          meta: {
            breadcrumbs: {
              title: "Validação do serviço",
              label: "Validação",
              icon: "card-checklist",
              destination: `/homologation/:homologation_id/service/:service_id/provider/validation`,
            },
            transition: "fade",
          },
          component: () => import("@/pages/Homologation/Services/Validation/Provider"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/admin/validation",
          meta: {
            breadcrumbs: {
              title: "Validação do serviço",
              label: "Validação",
              icon: "card-checklist",
              destination: `/homologation/:homologation_id/service/:service_id/admin/validation`,
            },
            transition: "fade",
          },
          component: () => import("@/pages/Homologation/Services/Validation/Admin"),
        },
      ],
    },
    {
      path:
        "/homologation/:homologation_id/service/:service_id/borrower/result/:provider_id/:report",
      meta: {
        breadcrumbs: {
          title: "Resultado prestador",
          label: "Resultado",
          icon: "card-checklist",
          destination: `/homologation/:homologation_id/service/:service_id/borrower/validation`,
        },
        transition: "fade",
      },
      component: () =>
        import("@/pages/Homologation/Services/Validation/Borrower/Result"),
    },
    {
      path:
        "/homologation/:homologation_id/service/:service_id/admin/validation/:provider_id/:ranking_id",
      meta: {
        breadcrumbs: {
          title: "Validação prestador",
          label: "Validação",
          icon: "card-checklist",
          destination: `/homologation/:homologation_id/service/:service_id/admin/validation`,
        },
        transition: "fade",
      },
      component: () => import("@/pages/Homologation/Services/Validation/Admin/Validation"),
    },
    modulesRoutes,
  ],
};
