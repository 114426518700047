import { api } from "@/services/api";

export default {
  state: {
    modules: [],
  },
  getters: {
    modules: (state) => state.modules,
  },
  mutations: {
    set_modules: (state, payload) => {
      state.modules = payload;
    },
  },
  actions: {
    get_all_modules({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/module/`)
          .then(({ data }) => {
            commit("set_modules", data);
            resolve(data);
          })
          .catch((err) => {
            commit("set_modules", err);
            reject(err);
          });
      });
    },
    get_modules({ commit }, service_id) {
      return new Promise((resolve, reject) => {
        api
          .get(`/service-validation/${service_id}/module/`)
          .then(({ data }) => {
            commit("set_modules", data);
            resolve(data);
          })
          .catch((err) => {
            commit("set_modules", err);
            reject(err);
          });
      });
    },
  },
};
