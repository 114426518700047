import { api } from "@/services/api";

export default {
  state: {
    service_validation: [],
    service_validation_history: [],
  },
  getters: {
    service_validation: (state) => state.service_validation,
    service_validation_history: (state) => {
      if (state.service_validation_history) {
        return state.service_validation_history;
      }
      return [];
    },
  },
  mutations: {
    list_service_validation(state, service_validation) {
      state.service_validation = service_validation;
    },
    service_validation_history_data(state, service_validation_history) {
      state.service_validation_history = service_validation_history;
    },
  },
  actions: {
    // provider
    get_service_validation({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/service-validation/${params.service_id}/`)
          .then(({ data }) => {
            commit("list_service_validation", data);
            resolve(data);
          })
          .catch((err) => {
            commit("list_service_validation");
            reject(err);
          });
      });
    },
    start_service_validation(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/service-validation/${params.service_id}/start/`)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_service_validation_history({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/service-validation/${params.service_id}/history/`)
          .then(({ data }) => {
            if (params.type) {
              commit("service_validation_history_data", data.history);
            }
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_service_validation_conversation(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(
            `/service-validation/${params.service_id}/conversation/?current_page=${params.current_page}&per_page=10`
          )
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_service_validation_conversation(_, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(
            `/service-validation/${params.service_id}/conversation/${params.id}/`,
            {
              ...params,
            }
          )
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_service_validation_conversation(_, params) {
      return new Promise((resolve, reject) => {
        api
          .delete(
            `/service-validation/${params.service_id}/conversation/${params.id}/`,
            {
              ...params,
            }
          )
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_service_validation_conversation(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/service-validation/${params.service_id}/conversation/`, {
            message: params.message,
          })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_service_validation_attachments(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/service-validation/${params.service_id}/attachments/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_service_validation_attachments(_, params) {
      return new Promise((resolve, reject) => {
        api
          .delete(
            `/service-validation/${params.service_id}/attachments/${params.id}/`
          )
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_service_validation_attachments(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/service-validation/${params.service_id}/attachments/`, {
            ...params,
          })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
