export const myEnterprise = {
  title: "Minha empresa",
  key: "informations-enterprise",
  url: "#",
  icon: "building",
  single: false,
  visible: ["provider", "borrower"],
  children: [
    {
      title: "Cadastro",
      key: "informations-enterprise",
      url: "/my-enterprise/register/general",
      icon: "building",
      single: true,
      visible: ["provider", "borrower"],
    },
    {
      title: "Clientes",
      key: "my-enterprise-secure",
      url: "/my-enterprise/clients",
      icon: "user-check",
      single: true,
      visible: ["provider"],
    },
    {
      title: "Financeiro",
      key: "financial",
      icon: "wallet",
      url: "/my-enterprise/financial/income",
      visible: ["provider"],
    },
    {
      title: "Pessoas",
      key: "human-resources",
      icon: "book-reader",
      url: "/my-enterprise/human-resources/jobs-position",
      visible: ["provider"],
    },
    {
      title: "Jurídico",
      key: "legal",
      icon: "file-shield-alt",
      url: "/my-enterprise/legal/labor-lawsuits/list",
      visible: ["provider"],
    },
    // {
    //   title: "Prestadores",
    //   key: "provider",
    //   icon: "hard-hat",
    //   url: "/provider/list",
    //   visible: ["borrower"],
    // },
    {
      title: "Regularidade",
      key: "compliance",
      icon: "user-square",
      url: "/my-enterprise/compliance/certificates",
      visible: ["provider"],
    },
    {
      title: "Sistemas e Gestão",
      key: "system-management",
      icon: "server",
      url: "/my-enterprise/system-management/systems",
      visible: ["provider"],
    },
  ],
};
