import { api } from "@/services/api";
import store from "@/store";

function getParamViewProviderId(name, url = window.location.href) {
  name = name.replace(/[\\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default {
  state: {
    snapshot: {},
    snapshotList: {},
  },
  getters: {
    snapshot: (state) => state.snapshot,
    snapshotList: (state) => state.snapshotList,
  },
  mutations: {
    snapshot_data(state, snapshot) {
      state.snapshot = snapshot;
    },
    snapshot_list(state, snapshot) {
      state.snapshotList = snapshot;
    },
  },
  actions: {
    async get_snapshot({ commit, dispatch }, params) {
      const { is_preview, preview_personify, user } = await store.getters;

      // When admin check snapshots provider
      const preview_provider = getParamViewProviderId("preview_provider");

      const { service_id, type } = params;

      let provider_id;

      if (is_preview) {
        provider_id = preview_personify.id;
      } else if (preview_provider) {
        provider_id = preview_provider;
      } else {
        provider_id = params.provider_id ?? user.enterprise_id;
      }

      return new Promise((resolve, reject) => {
        const { user } = store.getters;

        api
          .get(`/snapshot/${type}/${provider_id}/${service_id}/`)
          .then(({ data, status }) => {
            if (!user.is_admin) {
              dispatch("get_service_validation_history", { service_id, type });
            }

            if (status === 404) {
              data.enabled = true;
            }
            commit("snapshot_list", data);
            resolve(data);
          })
          .catch((err) => {
            commit("snapshot_list");
            reject(err);
          });
      });
    },

    async set_snapshot({ commit }, params) {
      const { is_preview, preview_personify, user } = await store.getters;

      let provider_id;
      if (is_preview) {
        provider_id = preview_personify.id;
      } else {
        provider_id = user.enterprise_id;
      }

      const { service_id, type, data, method } = params;
      let funcCall = api.post
      if (method == "patch"){
        funcCall = api.patch
      }

      return new Promise((resolve, reject) => {
        funcCall(`/snapshot/${type}/${provider_id}/${service_id}/`, { data })
          .then(({ data }) => {
            commit("snapshot_data", data);
            resolve(data);
          })
          .catch((err) => {
            commit("snapshot_data");
            reject(err);
          });
      });
    },
  },
};
